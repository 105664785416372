<template>
    <div v-if="!isLoading">
        <div class="pb-5">
            <h3 class="text-center">{{ classroom.name }} Courses</h3>
            <div class="d-flex flex-row-reverse">
                <router-link :to="{ name: 'TrainerClassRoomDashboard'}" class="btn default-btn end-0">
                    To ClassRoom
                </router-link>
            </div>
            <div class="alert alert-success" v-if="success">
                {{ success }}
            </div>
            <div class="alert alert-danger" v-if="errors[0]">
                {{ errors }}
            </div>
        </div>
        <div class="row justify-content-center pt-5" v-if="classroom.courses.length">
            <div class="col-md-3" v-for="course in classroom.courses" :key="course.id">
                <router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img src="@/assets/img/courses/1.jpg" alt="images">
                        </div>
                        <div class="member-content">
                            <h6><router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">{{ course.title }}</router-link></h6>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="pt-5" v-else>
            <h3 class="text-center">No Course in This Classroom</h3>
        </div>
    </div>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import _debounce from 'lodash.debounce'
import axios from 'axios'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            user: {},
            classroom: {},
            searchedCourses:{},
            search:'',
            success:false,
            isOpen: false,
            isSearching: false,
            form:{
                classroomId: this.$route.params.id
            }
        }
    },
    computed: {
        debouncedOnChange () {
            if(this.search) {
                return _debounce(this.findCourses, 700);
            }
            return _debounce(this.nullFunction)
        },
    },
    methods:{
        getCourses(){
            let id = this.$route.params.id
           axios.get('https://apitraining.vipawaworks.com/api/classroom/profile_with_courses/' + id).then(response => {
                this.classroom = response.data
            }).catch(errors => {
               this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        findCourses(){
            this.isSearching = true
            axios.post('https://apitraining.vipawaworks.com/api/course/search_course_by_keyword/' + this.search).then(response => {
                this.searchedCourses = response.data
                this.isSearching = false
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.isSearching = false
            }) 
        },
        nullFunction(){
            this.searchedCourses = []
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getCourses()
    }

}
</script>

<style>

</style>