<template>
<Navbar :user="user" />
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="row">
                <div class="col-md-2" id="sticky-sidebar">
                    <div class="sticky-top">
                        <Sidebar :user="user" />
                    </div>
                </div>
                <div class="col" id="main" >
                    <div v-if="user.status == 1">
                        <TraineeCourses />
                    </div>
                    <div v-else-if="user.status == 3">
                        <TrainerCourses />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/trainer/Sidebar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import TraineeCourses from '@/components/trainee/classroom/TraineeCourses.vue'
import TrainerCourses from '@/components/trainer/classroom/TrainerCourses.vue'
export default {
    components: { Navbar,Loading, Sidebar, TraineeCourses, TrainerCourses, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            user: {},
            classroom: {},
            searchedCourses:{},
            search:'',
            success:false,
            isOpen: false,
            isSearching: false,
            form:{
                classroomId: this.$route.params.id
            }
        }
    },
    methods:{
       
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
    },
    created(){
        document.title = `Class Room - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
    }
}
</script>

<style>

</style>