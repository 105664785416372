<template>
    <div v-if="!isLoading">
        <div class="pb-5">
            <h3 class="text-center">{{ classroom.name }} Courses</h3>
            <div class="d-flex flex-row-reverse">
                <router-link :to="{ name: 'TrainerClassRoomDashboard'}" class="btn default-btn end-0">
                    To ClassRoom
                </router-link>
            </div>
            <div class="alert alert-success" v-if="success">
                {{ success }}
            </div>
            <div class="alert alert-danger" v-if="errors[0]">
                {{ errors }}
            </div>
            <div class="form-75 dropdown-search">
                <input class="form-control me-2  gx-5 dropdown-input" type="search" placeholder="Type search trainees by emails" aria-label="Search" 
                v-model="search" @keydown.enter="isOpen = false" @keydown.esc="isOpen = false" @input="debouncedOnChange">
                <div id="passwordHelpBlock" class="form-text">Type course name to search from the system</div>
                <div class="dropdown-list">
                <div class="btn text-center shadow-lg ms-2 me-auto" v-if="isSearching">Searching...</div>
                <div class="row justify-content-center" v-else-if="searchedCourses.length">
                    <div class="col-md-6" v-for="course in searchedCourses" :key="course.id">
                        <button class="btn ms-2 me-auto shadow-lg" @click="addCourseToClassRoom(course.id)">
                            <div class="text-small">{{ course.title }}</div>
                            <div style="text-size: 10px;"> <span class="text-default"> {{ course.user_name }}</span></div>
                        </button>
                    </div>
                </div>
                <div v-else-if="search">
                    <button class="btn ms-2 me-auto shadow-lg">
                        <div class="text-small">Course Not Found</div>
                        <div style="text-size: 10px;"> <span class="text-default"> {{ search }}</span></div>
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center pt-5" v-if="classroom.courses.length">
            <div class="col-md-2" v-for="course in classroom.courses" :key="course.id">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="@/assets/img/courses/1.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h6><a href="#">{{ course.title }}</a></h6>
                        <ul class="social">
                            <li>
                                <a @click="removeFromClassroom(course.id)" class="btn instagram">
                                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-5" v-else>
            <h3 class="text-center">No Course in This Classroom</h3>
        </div>
    </div>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import _debounce from 'lodash.debounce'
import axios from 'axios'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            user: {},
            classroom: {},
            searchedCourses:{},
            search:'',
            success:false,
            isOpen: false,
            isSearching: false,
            form:{
                classroomId: this.$route.params.id
            }
        }
    },
    computed: {
        debouncedOnChange () {
            if(this.search) {
                return _debounce(this.findCourses, 700);
            }
            return _debounce(this.nullFunction)
        },
    },
    methods:{
         addCourseToClassRoom(id){
            axios.post('https://apitraining.vipawaworks.com/api/course/assign_to_classroom/' + id, this.form).then(response => {
                this.success = response.data.success
                this.errors = false
                this.getCourses()
                 this.$forceUpdate()
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.success = false
            }) 
        },
        removeFromClassroom(id){
            axios.post('https://apitraining.vipawaworks.com/api/course/remove_from_classroom/' + id, this.form).then(response => {
                this.success = response.data.success
                this.errors = false
                this.getCourses()
                 this.$forceUpdate()
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.success = false
            }) 
        },
        getCourses(){
            let id = this.$route.params.id
           axios.get('https://apitraining.vipawaworks.com/api/classroom/profile_with_courses/' + id).then(response => {
                this.classroom = response.data
            }).catch(errors => {
               this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        findCourses(){
            this.isSearching = true
            axios.post('https://apitraining.vipawaworks.com/api/course/search_course_by_keyword/' + this.search).then(response => {
                this.searchedCourses = response.data
                this.isSearching = false
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.isSearching = false
            }) 
        },
        nullFunction(){
            this.searchedCourses = []
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getCourses()
    }

}
</script>

<style>

</style>